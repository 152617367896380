/* .footeritemtext{
    width: 2vw;
} */

.funfont {
    font-family: 'Amatic SC', cursive;
    color: #F47736;
    font-weight: 800;
}
.youtube_like{
    /* width: 6vw;
    transform: rotate(60deg);
    font-size: 40px;
    background-color: #F36C38;
    color: white;
    padding: 6px; */
    font-size: 40px;
    background-color: #F36C38;
    color: white;
    padding: 6px;
    width: 60%;
}
.borderradiuscurve {
    border-radius: 18% 11% 3% 17%/2% 2% 34% 4%;
    border-top-left-radius: 18% 2%;
    border-top-right-radius: 11% 2%;
    border-bottom-right-radius: 3% 34%;
    border-bottom-left-radius: 17% 4%;
}

.footerimageclass {
    width: 18vw;
    position: relative;
    right: -891px;
}
.arrow-img{
    width: 6vw;
    transform: rotate(60deg);
}
.studentfooter {
    background-image: url(../../images/kk.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 24vh;
    overflow-y: hidden;
}
.footericon{
    font-size:30px;
    padding: 0px 10px;
    color: white;
}

.footer_top_content{
    font-size: 40px;
    text-decoration: none;
    cursor: pointer;
    color: #009846;
    margin-bottom: 27px;
}
.footer_bottom{
    color: white;margin: 0px;padding:5px ;
}
.footerimageclass {
    width: 18vw;
    right: 0;
    position: absolute;
    overflow-y: hidden;
}
.rotate_like{
    transform: rotate(-5deg);
    margin-left: -20vw
;
}
html, body {
    max-width: 100%;
    overflow-x: hidden;
}

/* for mobile */

@media screen and (max-width: 990px) {
    .footerimageclass {
        width: 49vw;
        top: -111px;
        right: 0px;
        position: absolute;
        overflow-y: hidden;
    }
}

