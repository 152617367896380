.carousel .slider-wrapper{
    width: 100%;
}
.carousel .thumb{
    width: 5%;
    height: 50px;
    /* display: none; */
}
.carousel{
    text-align: center;
}
.topimage2{
    position: absolute;
    width: 20vw;
    top: 0vh;
    right: 0;
}
.topimg3{
    position: absolute;
    width: 20vw;
    top: 47vh;
}


@media screen and (max-width: 990px) {
    .topimage2 {
        /* position: absolute;
        width: 36vw;
        top: 1vh;
        right: 0; */
        position: absolute;
        width: 36vw;
        top: -4vh;
        right: -13px;
    }
    .topimg3 {
        position: absolute;
        width: 49vw;
        top: 113vh;
        left: 27px;
    }
}
