.img_div1{
    background-image: url(../../images/why1.png)!important;
    height: 70vh;
    background-size: cover;
    background-position: center;
    position:relative;
                
}
.img_div2{
    background-image: url(../../images/why2.png)!important;
    height: 70vh;
    background-size: cover;
    background-position: center;
    position:relative;
                
}
.img_div3{
    background-image: url(../../images/why3.png)!important;
    height: 70vh;
    background-size: cover;
    background-position: center;
    position:relative;
                
}
.img_div22{
    background-image: url(../../images/bg.jpg)!important;
    height: 70vh;
    background-size: cover;
    background-position: center;
    position:relative;
}
.img_div33{
    background-image: url(../../images/bg.jpg)!important;
    height: 70vh;
    background-size: cover;
    background-position: center;
    position:relative;
}
.catchup{
    transform: rotate(-10deg);
    z-index: 2;
}
.fun{
    transform: rotate(-20deg);
    z-index: 1;
    margin-top: -30px;
}

.homeopenplayschoolh1{
    font-size: 50px;font-weight: 900; color: #E5097F; display: block;
}

.homeopenplayschoolh1 img{
    width: 3vw;transform: rotate(180deg);
}

.firstfranchisediv{
    background-image: url(.././../images/fixed1.jpg); background-position: top; background-size: cover; background-attachment: fixed;height: auto;
}

.firstfranchisediv a{
    background-color: white; color: #E5097F;font-weight: 900;
}

.secondfranchisediv{
    background-image: url(.././../images/fixed2.jpg); background-position: top; background-size: cover; background-attachment: fixed;height: auto;
}

.secondfranchisediv a{
    background-color: white; color: #E5097F;font-weight: 900;
}
.thirdfranchisediv{
    background-image: url(.././../images/fixed3.jpg); background-position: top; background-size: cover; background-attachment: fixed;height: auto;
}

.thirdfranchisediv a{
    background-color: white; color: #009846;font-weight: 900;
}
.Qualitybox{
    margin-top:50px;padding: 50px;
}

@media (max-width:990px){
    .borderradiuscurve{
        height:auto !important;
    }
}