body {
    /* background-color: #434c50; */
    min-height: 100vh;
    font: normal 16px sans-serif;
    /* padding: 40px 0; */
}

.container.gallery-container {
    background-color: #fff;
    color: #35373a;
    min-height: 100vh;
    /* padding: 30px 50px; */
}

.gallery-container h1 {
    text-align: center;
    margin-top: 50px;
    font-family: 'Droid Sans', sans-serif;
    font-weight: bold;
}

.gallery-container p.page-description {
    text-align: center;
    margin: 25px auto;
    font-size: 18px;
    color: #999;
}

.tz-gallery {
    padding: 40px;
}

/* Override bootstrap column paddings */
.tz-gallery .row>div {
    padding: 2px;
}

.tz-gallery .lightbox img {
    width: 100%;
    border-radius: 0;
    position: relative;
}

.tz-gallery .lightbox:before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -13px;
    margin-left: -13px;
    opacity: 0 !important;
    color: #fff;
    font-size: 26px;
    font-family: 'Glyphicons Halflings';
    content: '\e003';
    pointer-events: none;
    z-index: 9000;
    transition: 0.4s;
}


.tz-gallery .lightbox:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(46, 132, 206, 0.7);
    content: '';
    transition: 0.4s;
}

.tz-gallery .lightbox:hover:after,
.tz-gallery .lightbox:hover:before {
    opacity: 1;

}

.baguetteBox-button {
    background-color: transparent !important;
}

@media(max-width: 768px) {
    body {
        padding: 0;
    }
}



/* Head Design */
.gllerytopbgg {
    height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.906)), url(../../images/gallerynew2.jpg);
    background-position: right;
    background-size: cover;
    color: white;
    transition: .5s;
}


.gllerytopbgg div {
    padding: 10px 200px;
}



.gllerytopbg {
    border-radius: 3% 45% 13% 9%/35% 9% 7% 10%;
    background-color: #009846;
    color: white;
}

.gllerytopbgg h1 {
    color: white;
    font-size: 90px;
}

@media screen and (max-width: 990px) {
    .gllerytopbgg h1 {
        color: white;
        font-size: 70px;
    }

    .gllerytopbgg div {
        padding: 10px 40px;
    }
}

.gllerytopbgg div p {
    color: white;
    font-size: 20px;
}

/* gallary image */

/* .container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 8%;
} */

.gallery {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 18px;
}

.gallery img {
    width: 100%;
}

.lb-data .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999999;
    display: none !important;
}