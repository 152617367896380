.banner {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../component/images/Ikat_1800x.jpg);
    background-size: cover;
    background-position: center; */
    /* height: 90vh; */
    /* margin-top: 60px; */
    overflow: hidden;
    /* position: relative; */
    /* border-radius: 100% 0% 100% 0% / 0% 84% 16% 100%; */
    display: flex;
    /* justify-content: center; */
    align-items: flex-end;

}